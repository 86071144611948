var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"banner"},[_c('warning-tip',{attrs:{"code":"ITEM_CHECK_POOL"}}),_c('main-top',{attrs:{"title":"淘宝商品入池检测","desc":"商品降权检测","spend":"ITEM_CHECK_POOL"}},[_c('InputView',{attrs:{"extractProductId":true,"CanYouClick":_vm.CanYouClick,"placeholder-name":"请输入您的宝贝链接或宝贝ID"},on:{"sendSearchName":_vm.getSearchName}})],1),_c('bordure-row',{staticStyle:{"margin-bottom":"30px"},attrs:{"title":"","shadow":""}},[_c('div',[_c('div',{staticClass:"control-tool"},[_c('el-button',{attrs:{"type":"primary","size":"small"},on:{"click":_vm.downloadExcel}},[_vm._v("一键导出")])],1),_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.tableLoading),expression:"tableLoading"}],staticStyle:{"width":"100%","border":"1px solid #f4f4f4"},attrs:{"data":_vm.tableData,"size":"mini","header-cell-style":{
          background: '#f8f8f8',
          'font-family': 'PingFangSC-Regular',
          color: '#666666 !important',
          'letter-spacing': '0',
          'text-align': 'center',
          'font-weight': 'normal',
        }}},[_c('el-table-column',{attrs:{"align":"center","prop":"itemId","label":"商品ID"}}),_c('el-table-column',{attrs:{"align":"center","prop":"title","label":"商品标题"}}),_c('el-table-column',{attrs:{"align":"center","prop":"poolMsg","label":"入池情况"}}),_c('el-table-column',{attrs:{"align":"center","prop":"rankMsg","label":"权重情况"}}),_c('el-table-column',{attrs:{"align":"center","prop":"createTime","label":"创建时间"}})],1)],1)]),_c('Introduce',{attrs:{"introduce":_vm.introduce}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }